.booking-confirmed-container {
    font-family: 'Arial', sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .booking-header h1 {
    color: #333;
  }
  
  .booking-header p {
    color: #666;
  }
  
  .order-number {
    background: #eee;
    padding: 10px;
    margin-top: 10px;
  }
  
  .booking-details {
    display: flex;
    border-bottom: 2px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  
  .service-image img {
    max-width: 100px;
    margin-right: 20px;
  }
  
  .service-info h2 {
    margin: 0;
  }
  
  .service-info p {
    margin: 5px 0;
    color: #666;
  }
  
  button {
    background: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  button:hover {
    opacity: 0.8;
  }
  
  .booking-summary .summary-row,
  .booking-summary .summary-total {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  
  .summary-total {
    font-weight: bold;
  }
  
  .confirmation-footer {
    text-align: center;
    padding-top: 20px;
  }
  