.checkout-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0rem;
  }

.checkout-row {
    flex: 1 1 100%;
    /* width: 50%; */
    margin-bottom: .2rem;
  }
  @media (min-width: 768px) { /* Adjusts layout for screens wider than 768px */
    .city-select {
      margin-bottom: 20px;
    }
    .checkout-row {
      width: calc(50% - 0.5rem); /* Adjust width to account for the gap */
      flex-basis: calc(50% - 0.5rem); /* Use flex-basis for more accurate sizing */
      margin-bottom: 1rem;
    }
    .checkout-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
    .input-field {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  
  }

  .input-field {
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }

  .one-row{
    flex: 1 1 100% !important;
  }
  
  .select-date-time-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .select-date-time-text {
    text-align: right;
  }
  
  .timezone {
    text-align: left;
  }
  input,
  textarea {
    padding: 0.5rem;
    border: 1px solid #000000;
    background-color: #d5d5d5;
    border-radius: 0;
  }

  input:focus,
textarea:focus { 
    outline: none; 
    box-shadow: 0 0 3px 2px rgba(15, 158, 130, 0.5);
}

  /* button[type="submit"] {
    background-color: #0f9e82; 
    color: white;
    padding: 10px 20px;
    margin: 1rem 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; 
  } */
  
  button[type="submit"]:hover {
    background-color: #0a6b56; /* Darker green on hover */
  }
  
  .checkout-form{
    margin: 25px;
  }

  .city-selection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
  }
  
  .map-graphic {
    display: flex;
    height: 400px;
    margin-bottom: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 5px; 
  }
  .map-graphic img{
    width:auto;
    height: auto;
    margin-bottom: 20px;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 90%;
    max-height: 100%;
  }
  
  .city-options {
    display: flex;
    flex-direction: column;
  }
  
  .city-options label {
    margin-bottom: 10px;
  }
  /* Checkout date field values */
  .select-date-time-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    padding-top: 0px;
  }

  .layout-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .left-section{
    flex: 3;
  }
  .right-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the flex container */
  }
  .right-section-alt {
    flex-basis: 100%;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the flex container */
  }

  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .arrow-indicator {
    border: solid #000;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transition: transform 0.3s ease;
  }
  
  .down {
    transform: rotate(45deg);
  }
  
  .up {
    transform: rotate(-135deg);
  }
  
  .service-details-dropdown {
    cursor: pointer;
    padding: 10px;
    padding-left: 0px;
    /* background-color: #f0f0f0; */
    width: 100%;
    max-width:100%;
    box-sizing: border-box; /* Includes padding and border in the element's width */

    overflow: hidden; /* Prevents content from overflowing during transition */
  }
  
  .service-details-content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s ease, max-height 0.3s ease;
  }
  
  /* Class to add when the dropdown is active */
  .service-details-content.active {
    opacity: 1;
    max-height: 500px; /* Adjust based on the content size */
  }
  .service-details-dropdown:hover {
    /* background-color: #e0e0e0; Slightly darker on hover */
  }
  
  .service-details-content {
    display: block; /* Ensure it's displayed as a block to format contents correctly */
    border-top: 1px solid #ccc; /* Light border for separation */
  }
  
  .light-text {
    color: #666; /* Lighter text color for specific details */
    margin: 5px 0; /* Spacing between detail lines */
  }

  /* Additional styles for the dropdown content */
  .service-details-content p {
    margin: 4px 0; /* Tighter spacing between paragraphs */
  }

  .timeslot-buttons {
    gap: 10px;
  }
  .timeslot-buttons button{
    margin-top: 0px;
  }
  .city-select {
    margin-bottom: 0px;
  }
  
  .timeslot-section {
    margin-bottom: 20px;
    max-width: 400px;   
    flex-grow: 1;                /* Allow timeslots section to take remaining space */
  }
  
  
  .timeslot-buttons {
    display: grid; 
    grid-template-columns: repeat(2, 1fr); /* Create two equal columns */
    gap: 10px; /* Spacing between buttons */
  }
  
  .timeslot-buttons button {  
    padding: 8px 32px;
    border: 1px solid;
    border-radius: 0px;
    /* background-color: rgb(255, 64, 64); */
    background-color: white;
    /* color: #0f9e82; */
    color: black;
    cursor: pointer;
    border: 1.5px solid #0f9e82; 
    /* border: 1px solid rgb(255, 64, 64); */
    font-size: 16px;
    
  }
  

.content-wrapper {
  display: flex;
  flex-wrap: wrap; /* Allows shifting for mobile */
}

.calendar-section {
  min-width: 400px; /* Adjust as needed */
  margin-right: 20px;
  margin-bottom: 20px;
  display:flex;
}
.timeslot-buttons button:hover{
  background-color: lightgray;
}
.timeslot-buttons button.selected {
  background-color: #0f9e82;
  /* background-color: white; */
  /* border: 1px solid black;   */
  
  /* border: 1px solid rgb(255, 64, 64);
  border: 1px solid rgb(255, 64, 64); */
  border: 1.5px solid #0f9e82; 
  color: black;
  color: white;
}

/* Calendar Styling */
.react-calendar {
  border: none !important;
  border-radius: 0px;
  font-family: sans-serif; 
  width: auto !important;
  height: 100% !important;
  max-width: 400px !important;
}
.react-calendar__month-view__days__day--weekend{
  color: black !important;;
}

.react-calendar__tile {
  background-color: #f0f0f0; 
}

.react-calendar__tile--now {
  background-color: #0f9e82; 
}

.react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button{
  display: none;
}
.react-calendar__navigation__next-button, .react-calendar__navigation__prev-button{
  font-size: 50px;
}

.react-calendar__navigation__label__labelText{
  font-size: 20px;
}
/* Media Query for Mobile Layout */
@media (max-width: 768px) { /* Or your breakpoint */
  .content-wrapper {
    flex-direction: column; 
  }
  .layout-wrapper {
    flex-direction: column;
  }

  .left-section, .right-section {
    /* Reset flex-basis and width to ensure they take up the full width of their container */
    flex-basis: 100%;
    width: 100%;
  }
  .layout-wrapper {
    flex-direction: column;
  }

  .calendar-section, .timeslot-section {
    /* Ensure full width utilization and reset any margins that were applied for desktop */
    width: 100%;
    max-width: 3000px;   
    min-width: auto; /* Reset any min-width set for desktop */
    margin-right: 0; /* Reset any right margin on the calendar section */
  }
}


/* end Checkout date field values */
.checkout-navigation-button{
  margin-top: 5px;
  padding: 8px 32px;
  border: none;
  border-radius: 0px;
  background-color: rgb(255, 64, 64); /* Example - update style as needed */
  color: white;
  cursor: pointer;
  font-size: 18px;
  width: 150px;
}
/* end Checkout date field values */
.checkout-navigation-button-final{
  margin-top: 5px;
  padding: 8px 32px;
  border: none;
  border-radius: 0px;
  background-color: rgb(255, 64, 64); /* Example - update style as needed */
  color: white;
  cursor: pointer;
  font-size: 18px;
  width: 150px;
  height:auto;
}


/* end Checkout date field values */
.checkout-navigation-button:hover{
  background-color: #09371F; /* Example - update style as needed */
  color: white;
  transition: background-color 0.3s ease; /* Subtle color change on hover */
}

.checkout h2{
  font-size: 32px !important;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.checkout h4{
  font-size: 18px !important;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 10px;
}
.back-button:hover{
cursor: pointer;
text-decoration: underline;
}


.navbarSpacer{
  margin-top: 100px;
}

.date-arrow{
  height: 22px;
  width: 22px;
  padding: 0px;
  background-color: white;
  border: 1px solid #0f9e82;
  font-size: 16px;
  color:black;
}

.date-dropdown{
  /* color: #0f9e82; */
  border: 1px solid #0f9e82;
  font-size: 16px;
  border-radius: 0px;
}

.no-timeslots-message{
  min-width: 50vw;
}
@media screen and (max-width: 768px) {
  .navbarSpacer{
    margin-top: 75px;
  }
  .no-timeslots-message{
    min-width: 75vw;
  }
  
}

.error-message {
  background-color: #ffebeb;
  border: 1px solid #ff0000;
  color: #ff0000;
  padding: 10px;
  margin-bottom: 20px;
}

.show-less-link,.show-more-link{
  color: rgb(255, 64, 64);
  cursor: pointer;
  text-decoration: underline;
}

.booking-details-subheading p{
  margin:0;
}

.checkout-navigation-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}